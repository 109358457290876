body {
    background: #111;
    display: flex;
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 5em 0;
  }

#root {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.inputs-container {
  display: flex;
  justify-content: center;
  gap: 0.5em;
  margin-bottom: 1em;
}

.candles-container {
  flex: 1;
  max-height: 600px;
  display: flex;
  justify-content: end;
  align-items: flex-end; /* Align candles at the bottom */
  gap: 20px; /* Space between candles */
}

.candles-container > * {
  flex: 1;
}
